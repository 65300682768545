import { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/UI/Loader";
import TelegramIcon from "../../icons/TelegramIcon";
import TwitterIcon from "../../icons/TwitterIcon";
import { UserTypeReturning, UserTypeRegistered } from "../../general.js";
import { telegramState } from "../../hooks/telegramState";
import "./style.css";

import { 
	GameOpenViewAction, 
	// GameStartClickAction, 
	GameFollowTgClickAction, 
	GameFollowXClickAction 
  } from '../../redux/actions/googleAnalyticsActions.js'
import { set } from "lodash";

const isMobileDevice = () => {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;

	return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(
		userAgent
	);
};

const Dashboard = () => {
	const dispatch = useDispatch(); 
	const { t } = useTranslation();
	const AuthState = useSelector((state) => state.Auth);
	const navigate = useNavigate();

	const navigateToHome = useCallback(() => {
		if (sessionStorage.getItem("token") && AuthState?.token) {
			navigate(`/home`);
		}
	}, [AuthState?.token, navigate]);

	useEffect(() => {
		dispatch(GameOpenViewAction())
	}, [dispatch])

	useEffect(() => {
		if (!isMobileDevice()) {
			navigate(`/scan-qr`);
			return;
		}
	}, [navigate]);

	useEffect(() => {
	
		if (telegramState.initialized) {
			if (AuthState.loading) return;
			if (AuthState.user?.type === UserTypeRegistered) {
				setTimeout(() => {	navigate(`/onboarding`); }, 1000);
			} else if (AuthState.user?.type === UserTypeReturning) {
				setTimeout(() => {	navigateToHome(); }, 1000);				
			}
		}

	}, [AuthState.user, AuthState.loading,telegramState.initialized, navigate, navigateToHome]);


	const handleFollowXClick= () => {
		dispatch(GameFollowXClickAction())
	}
	
	const handleFollowTgClick= () => {
		dispatch(GameFollowTgClickAction())
	}


	return (
		<div className="dashboardPage h-screen">
			<div className="loader-bg  w-full text-white h-full font-bold relative">
				<div className="loader -translate-x-2/4 -translate-y-2/4 absolute left-2/4 top-[7%]">
					{" "}
					<Loader />
				</div>
				<div className="absolute text-center -translate-x-2/4 w-full left-2/4 bottom-[26%]">
					<h3 className="text-[75px] text-xl mb-[40px]">
						{t("dashboard.title")}
					</h3>
					<p className="text-[40px]" style={{ 'white-space': 'pre-line', marginLeft:'20px', marginRight: '20px', lineHeight: '1.1' }}>{t("dashboard.description")}</p>
				</div>
				<div className="absolute text-center -translate-x-2/4 w-full left-2/4 bottom-[5%]">
					{/* <p className="text-[13px] text-[#bdbdbd] font-normal">stay tuned</p> */}
					<h3 className="text-[23px] font-semibold">
						{t("dashboard.tagline")}
					</h3>
					<div className="flex justify-center mt-3 gap-[35px]">
						<span className="w-10 h-10 flex items-center justify-center" onClick={handleFollowTgClick} >
							<TelegramIcon />
						</span>
						{/* <span className="w-10 h-10 flex items-center justify-center"><YoutubeIcon/></span> */}
						<span className="w-10 h-10 flex items-center justify-center" onClick={handleFollowXClick}>
							<TwitterIcon />
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
