import React from "react";
import phantomIcon from "../../assets/images/phatom.png";
import solflareIcon from "../../assets/images/solfare.png";

const CreateAtaPopup = ({ onClose }) => {
  const link = "https://ata.theassadgame.com";

  const openPhantomApp = () => {
    navigator.clipboard.writeText(link);
    const deeplink = `phantom://open?url=${encodeURIComponent(link)}`;
    window.open(deeplink, '_blank');
    onClose();
  };

  const openSolflareApp = () => {
    navigator.clipboard.writeText(link);
    const deeplink = `solflare://browser?url=${encodeURIComponent(link)}`;
    window.open(deeplink, '_blank');
    onClose();
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      dispatchErrorToast(dispatch, "Link Copied", "The link has been copied to the clipboard.", "OK", '');
    }
    onClose();
  };

  const title = "Wallet Not Supported";
  const message = "Your wallet does not support\nthis token.\nPlease open this link in your wallet app in order to prepare your wallet for the claim.\n\n" + link;
  const ok_button = "Open Solflare";
  const cancel_button = "Open Phantom";
  const copy_link = "Copy Link";

  return (
    <div className="flex items-center flex-col justify-center fixed h-full z-[999] left-0 top-0 w-full">
      <div className="max-w-[300px] bg-[#030303] p-7 rounded-3xl shadow-[0px_0px_8px_rgba(164,142,56,1)]">
        {/* Title */}
        <h3 className="text-center text-2xl leading-7 my-2 text-[#fff] font-bold">
          {title}
        </h3>

        {/* Message */}
        <p
          className="text-center font-normal text-[14px] text-[#fff]"
          dangerouslySetInnerHTML={{
            __html: message.replace(/\n/g, "<br/>"),
          }}
        ></p>

        {/* Solflare Button */}
        <div className="text-center mt-4">
          <button
            onClick={openSolflareApp}
            className="flex items-center justify-center btnBgEx text-[#fff] font-semibold"
          >
            <img
              src={solflareIcon}
              alt="Solflare Icon"
              className="w-5 h-5 mr-3"
            />
            <span>{ok_button}</span>
          </button>
        </div>

        {/* Phantom Button */}
        <div className="text-center mt-1">
          <button
            onClick={openPhantomApp}
            className="flex items-center justify-center btnBgEx text-[#fff] font-semibold"
          >
            <img
              src={phantomIcon}
              alt="Phantom Icon"
              className="w-5 h-5 mr-3"
            />
            <span>{cancel_button}</span>
          </button>
        </div>



        {/* Cancel Button (if provided) */}
        {cancel_button && (
          <div className="text-center mt-2 mb-[-8px]">
            <button
              onClick={copyLink}
              className="text-[#FFC107] font-medium hover:underline"
            >
              {copy_link}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateAtaPopup;
