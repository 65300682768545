import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import tigerCoin from "../../assets/images/tigerCoin.png";
import wallet from "../../assets/images/wallet.png";
import btnBgBigBlack from "../../assets/images/btnBgBigBlack.png";
import buttonFriendsBg from "../../assets/images/claim_button.png";
import btnBgBig from "../../assets/images/btnBgBig.png";
import copyIcon from "../../assets/images/copy.png";
import { useBackButton } from '../../hooks/useBackButton.jsx';
import { preventCloseWithSwipe } from '../../utils/preventCloseWithSwipe.js';
import { footerHeight } from '../../general.js';
import { getPopupMessageAction } from '../../redux/actions/messageActions.js';
import DynamicPopUpComponent from "../../components/UI/DynamicPopUpComponent";
import Loader from "../../components/UI/Loader";
import {CheckUserCoins} from "../../redux/actions/gameActions";
import ClaimPopup from "./ClaimPopup";
import CreateAtaPopup from "./CreateAtaPopup";
import axios from "../../config/axiosConfig";
import { dispatchErrorToast } from "../../utils/functions";
import "./style.css";

const fetchAirdropData = async () => {
  try {
		const response = await axios.get(`airdrop`);

    console.log(response);
		if (response?.status === 200) {      
			return {
        tokensClaimed: response?.data.tokens_claimed,
        tokensAvailable: response?.data.tokens_to_claim,
        walletAddress: response?.data.wallet ?? "",
      };
		}
	} catch (e) {
    console.log(e)
	}
};

const claim = async (onCopyHandler) => {
  try {
    const response = await axios.get(`/claim_airdrop`, {
      validateStatus: function (status) {
        // Accept status codes 200, 405, and anything less than 500
        return status === 200 || status === 405 || status < 500;
      },
    });
  
    if (response?.status === 405) {
      // Handle 405 status code here
      return null;
    }
  
    const isSuccessful = response?.status === 200;
  
    return {
      title: response?.data?.title || (isSuccessful ? "Claim Successful" : "Claim Failed"),
      message: response?.data?.message || (isSuccessful 
        ? t('airdrop.claim_finish_success') 
        : t('airdrop.claim_finish_failed')),
      ok_button: response?.data?.ok_button || "OK",
    };
  } catch (error) {
    console.error("Error claiming tokens:", error);
  
    const status = error.response?.status;
    if (status === 500) {
      return {
        title: error.response?.data?.title || "Claim Failed",
        message: error.response?.data?.message || t('airdrop.claim_server_error'),
        ok_button: error.response?.data?.ok_button || "OK",
      };
    }
  
    return {
      title: "Claim Failed",
      message: "An unexpected error occurred. Please try again later.",
      ok_button: "OK",
    };
  }  
};


const Airdrop = () => {
  useBackButton("/airdrop", "/home");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const GameState = useSelector((state) => state.Game);
  const [airdropData, setAirdropData] = useState({ tokensClaimed: 0, tokensAvailable: 0, walletAddress: "" });
  const [connected, setConnected] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dots, setDots] = useState(".");
  const [claimLoading, setClaimLoading] = useState(false);
  const [showClaimPopup, setShowClaimPopup] = useState(false);
  const [claimResultText, setClaimResultText] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [showAtaPopup, setShowAtaPopup] = useState(false);

  useEffect(() => {
    // Timer to update dots
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : "."));
    }, 300); // Update every 500ms

    return () => clearInterval(interval); // Clean up the interval on unmount
  }, []);

  const fetchData = async () => {
    setLoading(true); // Start loading
    const data = await fetchAirdropData();
    setAirdropData(data);
    setConnected(data.walletAddress !== "");
    setLoading(false); // Stop loading
  };

  useEffect(() => {
      dispatch(CheckUserCoins());
  }, [dispatch]);

  useEffect(() => {
      preventCloseWithSwipe();
      fetchData();
  }, [GameState.coins_update_completed]);
  
  const truncateAddress = (addr) => {
    if (!addr) return "";
    var startLength = 8;
    var endLength = 8;
    if (addr.length > startLength + endLength) {
      return `${addr.slice(0, startLength)}...${addr.slice(-endLength)}`;
    }
    return addr;
  };

  const handleConnectWallet = () => {
    navigate("/connect-wallet");
  };

  const handleDisconnectWallet = async () => {
    setLoading(true); // Show loader during the network request
    try {
      const response = await axios.post("set_wallet", {
        wallet: "", // Send an empty string as the wallet address
      });
  
      if (response?.status === 200) {
        setConnected(false); // Update the connected state
        setAirdropData(prev => ({ ...prev, walletAddress: "" })); // Clear walletAddress
      } else {
        console.error("Failed to disconnect wallet: Unexpected response status");
      }
    } catch (error) {
      console.error("Failed to disconnect wallet:", error);
    } finally {
      setLoading(false); // Hide loader after the request
    }
  };

  const handleClaimClick = () => {
    if (!connected) {
      navigate("/connect-wallet");
      return;
    }

    // Show validation popup
    setPopupData({
      type: "warning",
      title: t('airdrop.claim_popup_title'),
      message: t('airdrop.claim_popup_desc'),
      ok_button: t('airdrop.claim_popup_btn_confirm'),
      cancel_button: t('airdrop.claim_popup_btn_cancel'),
      onOk: () => handleClaim(),
      onCancel: () => setShowClaimPopup(false),
    });
    setShowClaimPopup(true);
  };

  const handleCopyWalletAddress = () => {
    navigator.clipboard.writeText(airdropData.walletAddress);
    dispatchErrorToast(dispatch, "Wallet Address Copied", "Your wallet address has been copied to the clipboard.", "OK", '');
  };


  const handleClaim = async () => {
    setShowClaimPopup(false);
    setClaimLoading(true);

    const onCopyHandler = (link) => { 
      setPopupData(null);
      copyLink(link);      
    };
    const claimResponse = await claim(onCopyHandler);
    console.log('claimResponse:', claimResponse);
    if (claimResponse == null) {
      setClaimLoading(false);
      setShowAtaPopup(true);
      return;  
    }

    setClaimLoading(false);

    if (!claimResponse.onOk) {
      claimResponse.onOk = () => {
        setPopupData(null);
        fetchData(); // Refresh data after claim
      };
    }

    if (claimResponse) {
      // Show result popup from claim response
      setPopupData({
        type: "info",
        title: claimResponse.title,
        message: claimResponse.message,
        ok_button: claimResponse.ok_button,
        onOk: claimResponse.onOk,
      });
      setShowClaimPopup(true);
    }
  };

  let condition = false;
  if (navigator.userAgent == ("Mozilla/5.0 (iPhone; CPU iPhone OS 18_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148")) {
    condition = true;
  }

  return (
    <div
      className="earnBg h-full text-white overflow-auto pb-[70px] coustom-scroll relative"
      style={{ height: `calc(100dvh - ${footerHeight}px)` }}
    >
      {claimLoading && (
          <Loader />
        )}
      
      <div className="wrapperScroll flex flex-col min-h-full mt-[-20px]">
        <div className="px-3 flex-grow">
          <div className="relative flex justify-center items-center">
            <div 
              className="absolute rounded-full" 
              style={{
                height: '260px', 
                width: '260px', 
                background: 'radial-gradient(circle at 50% 55%, rgba(230,170,73,0.9) 0%, rgba(22, 28, 30, 0) 47%)', 
              }}
            />
            <img
              src={tigerCoin}
              alt="Dollar Image"
              style={{ height: '180px', width: '180px', marginTop: '30px', zIndex: '1' }}
              className="rounded-full"
            />
          </div>

          <h1 className="mt-1 text-center text-[32px] font-bold capitalize">
            {t('airdrop.title')}
          </h1>

          <div className="text-center mt-3">
            <p className="font-normal" style={{ color: "rgba(230, 170, 73, 1)", fontSize: '17px', lineHeight: '1.2' }}>
              {t('airdrop.tokens_claimed')}
            </p>
            <p className="font-normal" style={{ color: "rgba(230, 170, 73, 1)", fontSize: '17px', lineHeight: '1.5' }}>
            {loading ? dots : (Math.round(airdropData.tokensClaimed) || 0).toLocaleString()}
            </p>
          </div>
   
          <button
            className="w-full text-sm rounded-full font-semibold flex items-center px-4 py-[12px]"
            style={{
              backgroundImage: `url(${btnBgBigBlack})`,
              backgroundSize: 'contain', // Ensure the image fits within the button
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center', // Center the image
              margin: '0', // Add margin for spacing from the sides
              height: 'auto', // Allow height to adapt if needed
            }}
          >
            <div className="d-flex items-center w-full justify-start">
              <img
                className={`h-20 w-20 ${!condition ? 'ml-[-10px]' : ''}`}
                src={wallet}
                alt="wallet"
              />
              
              <div className="ml-3 text-left" style={{ fontSize: '18px', lineHeight: '1.4' }}>
                <div>{t('airdrop.tokens_available')}</div>
                <div>{loading ? dots : (Math.round(airdropData.tokensAvailable) || 0).toLocaleString()}</div>
              </div>
            </div>
          </button>

          <div className="relative flex justify-center items-center mt-2">
            <button
              onClick={handleClaimClick}
              className={`bg-full flex items-center justify-center gap-2 font-semibold rounded-xl text-[#2F2F2F] h-[50px] px-2 py-2 bg-no-repeat ${
                condition ? 'ml-[18px] mr-[18px]' : 'ml-2 mr-2'
              }`}
              style={{
                backgroundImage: `url(${buttonFriendsBg})`,
                fontSize: '17px',
                width: '385px',
              }}
              disabled={loading || airdropData.tokensAvailable === 0}
            >
              <h2 className="text-md">{t('airdrop.claim_tokens_btn')}</h2>
            </button>
          </div>

      
        </div>

        
        <div className="flex flex-col items-center justify-center mt-[40px]">
          {connected && (
            <div className="mb-0 text-center">
              <p 
                className="text-md" 
                style={{ 
                  color: "rgba(230, 170, 73, 1)", 
                  fontSize: '15px', 
                  lineHeight: '1.2',
                  fontWeight: 'normal' 
                }}
              >
                Wallet address:
              </p>
              <div className="flex items-center justify-center mt-0">
                <span className="text-sm break-all" style={{ 
                  color: "rgba(230, 170, 73, 1)", 
                  fontSize: '15px', 
                  lineHeight: '1.2',
                  fontWeight: 'normal' 
                }}>
                  {truncateAddress(airdropData.walletAddress)}
                </span>
                <button
                  className="bg-transparent border-none p-0 ml-2"
                  onClick={handleCopyWalletAddress}
                >
                  <img 
                    src={copyIcon}
                    alt="Copy" 
                    className="w-4 h-4"
                  />
                </button>
              </div>
            </div>
          )}

          <span
            onClick={connected ? handleDisconnectWallet : handleConnectWallet}
            className="underline text-white text-[16px] cursor-pointer mt-2"
            style={{ textUnderlineOffset: '4px' }}
          >
            {connected ? "Disconnect Wallet" : "Connect Wallet"}
          </span>

        </div>
      </div>

      
      {showAtaPopup && (
        <CreateAtaPopup
          onClose={() => setShowAtaPopup(false)}
        />
      )}

      {showClaimPopup && popupData && (
        <ClaimPopup
          type={popupData.type}
          title={popupData.title}
          message={popupData.message}
          ok_button={popupData.ok_button}
          cancel_button={popupData.cancel_button}
          onOk={popupData.onOk}
          onCancel={popupData.onCancel}
        />
      )}
    </div>
  );
};

export default Airdrop;