const TwitterIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" transform="scale(1.75, 1.75)" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.4999 3L4.96442 21H3.10742L18.6434 3H20.4999Z" fill="#9D6B30" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.5717 20.5L3.91016 3.5H8.38866L20.0502 20.5H15.5717Z" fill="black" />
            <path d="M7.86194 4.5L18.1509 19.5H16.0979L5.80894 4.5H7.86194ZM8.65194 3H2.96094L15.3079 21H20.9989L8.65194 3Z" fill="url(#paint0_linear_1371_16665)" />
            <defs>
                <linearGradient id="paint0_linear_1371_16665" x1="11.9799" y1="3" x2="11.9799" y2="21" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BD964A" />
                    <stop offset="1" stopColor="#8E5C2B" />
                </linearGradient>
            </defs>
        </svg>
    );
};
export default TwitterIcon;
