const TelegramIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" transform="scale(1.75, 1.75)" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1371_16672)">
                <path d="M9.41718 15.1814L9.02018 20.7654C9.58818 20.7654 9.83418 20.5214 10.1292 20.2284L12.7922 17.6834L18.3102 21.7244C19.3222 22.2884 20.0352 21.9914 20.3082 20.7934L23.9302 3.82141L23.9312 3.82041C24.2522 2.32441 23.3902 1.73941 22.4042 2.10641L1.11418 10.2574C-0.338822 10.8214 -0.316822 11.6314 0.867178 11.9984L6.31018 13.6914L18.9532 5.78041C19.5482 5.38641 20.0892 5.60441 19.6442 5.99841L9.41718 15.1814Z" fill="url(#paint0_linear_1371_16672)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_1371_16672" x1="11.9997" y1="2" x2="11.9997" y2="22.0001" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#BD964A" />
                    <stop offset="1" stopColor="#8E5C2B" />
                </linearGradient>
                <clipPath id="clip0_1371_16672">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default TelegramIcon;