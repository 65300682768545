import {
  ADD_COIN_REQUEST,
  ADD_COIN_SUCCESS,
  ADD_COIN_FAILED,
  SUBTRACT_COIN_REQUEST,
  SUBTRACT_COIN_SUCCESS,
  SUBTRACT_COIN_FAILED,
  UPDATE_LOCAL_COINS,
  UPDATE_ENERGY,
  DECREASE_ENERGY,
  INCREASE_ENERGY,
  INCREASE_CLICKS,
  ADD_TO_LOCAL_COINS,
  GET_TOTAL_USERS_RESPONSE,
  CHECK_USER_COINS_RESPONSE,
  CHECK_USER_COINS,
  REPORT_CLICKS,
} from "../actions/gameActions";
/* import { CheckUserCoins } from "../../redux/actions/gameActions"; */
import {
  ClickCountKey,
  EnergyCountKey,
  EnergyLastUpdatedKey,
  UserPointsKey,
} from "../../general.js";
import moment from "moment";

const getInitialCoins = () => {
  const points = localStorage.getItem(UserPointsKey);
  return points ? parseInt(points) : 0;
};

const getInitialClickCounts = () => {
  const clickCount = localStorage.getItem(ClickCountKey);
  return clickCount ? parseInt(clickCount) : 0;
};

const POINTS_TO_ADD = 1;
const _ENERGY = 1000;

const getInitialEnergy = () => {
  const energyCount = +localStorage.getItem(EnergyCountKey) || 1000;

  const lastUpdated = moment(
    localStorage.getItem(EnergyLastUpdatedKey) ?? moment()
  );

  const seconds = Math.floor(
    moment.duration(moment().diff(lastUpdated)).asSeconds()
  );

  return Math.min(
    (energyCount ? parseInt(energyCount) : 1000) + seconds * POINTS_TO_ADD,
    _ENERGY
  );
};

const saved = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
  return data;
};

const initialState = {
  coins: getInitialCoins(),
  energy: getInitialEnergy(),
  clicks: getInitialClickCounts(),
  totalUsers: 0,
  userCoins: 0,
  add_loading: false,
  sub_loading: false,
  coins_update_completed: false,
};

export const GameReducer = (state = initialState, action) => {  
  switch (action.type) {
    case UPDATE_LOCAL_COINS:
      return {
        ...state,
        coins: action.payload,
      };
    case ADD_TO_LOCAL_COINS:
      return {
        ...state,
        coins: state.coins + action.payload,
      };
    case UPDATE_ENERGY:
      localStorage.setItem(EnergyLastUpdatedKey, moment());
      return {
        ...state,
        energy: saved(EnergyCountKey, action.payload),
      };
    case DECREASE_ENERGY:
      localStorage.setItem(EnergyLastUpdatedKey, moment());
      return {
        ...state,
        energy: saved(
          EnergyCountKey,
          Math.min(state.energy - POINTS_TO_ADD, 1000)
        ),
      };
    case INCREASE_ENERGY:
      localStorage.setItem(EnergyLastUpdatedKey, moment());
      return {
        ...state,
        energy: saved(
          EnergyCountKey,
          Math.min(state.energy + POINTS_TO_ADD, 1000)
        ),
      };
    case INCREASE_CLICKS:
      return {
        ...state,
        clicks: state.clicks + 1,
      };
    case ADD_COIN_REQUEST:
      return {
        ...state,
        add_loading: true,
      };
    case ADD_COIN_SUCCESS:
      return {
        ...state,
        add_loading: false,
      };
    case ADD_COIN_FAILED:
      return {
        ...state,
        add_loading: false,
      };
    case SUBTRACT_COIN_REQUEST:
      return {
        ...state,
        sub_loading: true,
      };
    case SUBTRACT_COIN_SUCCESS:
      return {
        ...state,
        sub_loading: false,
      };
    case SUBTRACT_COIN_FAILED:
      return {
        ...state,
        sub_loading: false,
      };
    case GET_TOTAL_USERS_RESPONSE:
      return {
        ...state,
        totalUsers: action.payload.total_users,
      };
    case CHECK_USER_COINS:
        return {
          ...state,
          coin_update_completed: false,
        };
    case CHECK_USER_COINS_RESPONSE:
      return {
        ...state,
        coins: action.payload.coins,
        coins_update_completed: true,
      };
    case REPORT_CLICKS:
      return {
        ...state,
        clicks: 0,
      };
    default:
      return state;
  }
};
