import clsx from 'clsx';
import BlueIcon from '../../assets/images/blueIcon.png';
import DollarSmallIcon from '../../assets/images/dollorSmallIcon.png';
import AvatarCanvas from '../Avatar/AvatarCanvas.jsx';

const LeaderboardItem = ({ item, rank, isSticky }) => {
  //console.log(item);
  //console.log(item?.username, item?.avatar);
  return (
    <div className={clsx('bg-black max-w-md mx-auto rounded-xl', isSticky && 'sticky top-0 bottom-0')}>
      <div
        className={clsx("bottomBlockInner buttonBg my-2 max-w-md mx-auto items-center justify-between flex rounded-xl py-2 ps-2 pe-3 gap-2",
          isSticky && 'border !border-[#ffd742]')}
      >
        <div className="justify-between flex gap-3 leftSide items-center">
          <AvatarCanvas
                  key={""}
                  onlyView={true}
                  id={`avatarCanvas-${""}`}
                  layerCode={item?.avatar ? item?.avatar : ''}
                  width={45}
                  height={45}
                  source={'_assad'}
                />
          <div className="flex flex-col gap-1 w-4/6 w-100">
            <div className="flex gap-1 items-center">
              <img
                className="w-5 h-5 rounded-full"
                src={BlueIcon}
                alt=""
              />
              <p className="text-zinc-100 text-base capitalize font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
                {item?.username ? item.first_name : item.first_name}
              </p>
            </div>
            <div className="flex gap-1 items-center">
              <img
                className="w-5 h-5 rounded-full"
                src={DollarSmallIcon}
                alt=""
              />
              <p className="text-zinc-400 text-sm">{item?.coins}</p>
            </div>
          </div>
        </div>
        <label className="text-[#8b8e93] text-2xl tracking-tight">
          {rank}
        </label>
      </div>
    </div>
  );
};

export default LeaderboardItem;
