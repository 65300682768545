import React, { useState } from "react";
import { useBackButton } from '../../hooks/useBackButton.jsx';
import tigerCoin from "../../assets/images/tigerCoin.png";
import buttonFriendsBg from "../../assets/images/claim_button.png";
import "./style.css";
import DynamicPopUpComponent from "../../components/UI/DynamicPopUpComponent";
import axios from "../../config/axiosConfig";

const ConnectWallet = () => {
  useBackButton("/connect-wallet", "/airdrop"); // Handles back button navigation

  const [walletAddress, setWalletAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleNetworkRequest = async (address) => {
    try {
      const response = await axios.post("set_wallet", {
        wallet: address, // JSON payload with key "wallet" and value as the address string
      });
  
      // Check if the response status is 200
      if (response.status === 200) {
        return true; // Resolve as success
      }
    } catch (error) {
      console.error("Network request failed:", error);
    }
  
    return false; // Resolve as failure in case of any error or non-200 status
  };

  const onMessagePoupClose = () => {
      setWalletAddress("");
      setShowPopup(false);
    };

  const handleConnectWallet = async () => {
    if (!walletAddress.trim()) {
      console.error("Wallet address is empty!");
      return;
    }

    setLoading(true); // Show loading state
    const success = await handleNetworkRequest(walletAddress);

    setLoading(false); // Remove loading state
    if (success) {
      // Navigate back to the previous page on success
      window.history.back();
    } else {
      // Show a popup message on failure (Placeholder for now)
      setShowPopup(true);
    }
  };

  const errorPopupData = {
      status: 0,
      readDate: null,
      _id: "",
      createdDate: new Date().toISOString(),
      user: "",
      gameId: "",
      type: "toast",
      title: "Error",//severity.toLowerCase() === "danger" ? "Error" : "Notification",
      text: "Can't connect to wallet Please verify the wallet address and try again",
      buttons: [
          {
              text: "OK",
              action: "close"
          }
      ],
      action: "close",
      image: null,
      data: {},
      __v: 0        
  };

  return (
    <div
      className="earnBg h-full text-white overflow-auto pb-[70px] coustom-scroll relative"
      style={{ height: `calc(100dvh - 70px)` }}
    >
      <div className="wrapperScroll flex flex-col min-h-full">
        {/* Top Image Section */}
        <div className="flex flex-col items-center">
          <div className="relative flex justify-center items-center mt-auto">
            <div 
              className="absolute rounded-full" 
              style={{
                height: '260px', 
                width: '260px', 
                background: 'radial-gradient(circle at 50% 55%, rgba(230,170,73,0.9) 0%, rgba(22, 28, 30, 0) 47%)', 
              }}
            />
            <img
              src={tigerCoin}
              alt="Dollar Image"
              style={{ height: '180px', width: '180px', marginTop: '30px', zIndex: '1' }}
              className="rounded-full"
            />
          </div>

          <h1 className="mt-1 text-center text-[32px] font-bold capitalize">
            Connect Wallet
          </h1>
        </div>

        {/* Subtitle */}
        <div className="text-center mt-4">
          <p 
            className="font-normal" 
            style={{ 
              color: "rgba(180, 180, 180, 1)", 
              fontSize: '18px', 
              lineHeight: '1.5',
            }}
          >
            Enter your wallet address:
          </p>
        </div>

        {/* Input Field */}
        <div className="flex justify-center px-4 mt-4">
          <input
            type="text"
            value={walletAddress}
            onChange={(e) => setWalletAddress(e.target.value)}
            placeholder=""
            className="w-full rounded-xl px-4 py-[10px] text-black text-md"
            disabled={loading} // Disable input while loading
            style={{
              border: "1px solid rgba(200, 200, 200, 1)",
              backgroundColor: loading ? "rgba(200, 200, 200, 0.5)" : "rgba(255, 255, 255, 1)", // Dim background if disabled
            }}
          />
        </div>

        {/* Connect Wallet Button */}
        <div className="flex justify-center mt-6">
          <button
            className={`bg-full flex items-center justify-center gap-2 font-semibold rounded-xl text-[#2F2F2F] p-2 h-[50px] bg-no-repeat ${
              loading ? "opacity-70 cursor-not-allowed" : ""
            }`}
            style={{ 
              backgroundImage: `url(${buttonFriendsBg})`, 
              fontSize: '17px', 
              width: '365px',
            }}
            onClick={handleConnectWallet}
            disabled={loading} // Disable button while loading
          >
            {loading ? (
              <div className="animate-spin border-4 border-t-transparent border-gray-400 rounded-full w-6 h-6"></div>
            ) : (
              <h2 className="text-md">Connect Wallet</h2>
            )}
          </button>
        </div>
      </div>


      {showPopup && (
          <DynamicPopUpComponent
            isModalOpen={showPopup}
            closeModal={onMessagePoupClose}
            modalData={errorPopupData}
          />
        )}
    </div>
  );
};

export default ConnectWallet;
