import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import DollarImage from "../../assets/images/dollar.png";
import addFriendIcon from "../../assets/images/add_friend.svg";
import { readMessageAction } from '../../redux/actions/messageActions';
import { useCoins } from '../../hooks/useCoins';
import { AddCoinAction, AddToLocalCoinsAction } from '../../redux/actions/gameActions';
import { UserPointsKey } from '../../general';
import { UpdateBalanceAfterClaimAction } from '../../redux/actions/authActions';
import { claimRewardActions } from '../../redux/actions/friendsActions.js';
import IconComponent from './IconComponent'
import {fireFriendConfetti , fireArcadeConfetti} from '../../utils/confetti.js';
import { FriendsReferAcceptAction, FriendsReferBonusAccClickAction, FriendsReferBonusSndClickAction } from '../../redux/actions/googleAnalyticsActions.js';
import { getPopupMessageAction } from '../../redux/actions/messageActions.js';
import { useNavigate } from 'react-router-dom';

let defaultModalData = {
	status: 0,
	readDate: null,
	_id: "66a9061c7144339c1ac7b284",
	createdDate: "2024-07-30T15:26:20.873Z",
	user: "66a25c056b94ced564deaac0",
	gameId: "assad.game",
	type: "friend_referral",
	title: "New Friend Joined",
	text: "Your friend has joined the game",
	buttons: [
		{
			text: "Great",
			action: "close"
		},
		{
			text: "Invite",
			action: "window.Telegram.WebApp.openTelegramLink()",
			icon: "../../assets/images/add_friend.svg",
			action_url: "https://t.me/share/url?url=https://t.me/Celery_Cosmos_bot?start=66a8d0df547f430a5024a11b"
		}
	],
	action: "claim_referral",
	image: null,
	data: {
		friends: 1,
		Coins: 5000
	},
	__v: 0
};

const DynamicPopUpComponent = ({
	isModalOpen,
	closeModal,
	modalData = defaultModalData,
	...props
}) => {
	const AuthState = useSelector((state) => state.Auth);
	const { user } = AuthState;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { currentCoinsRef} = useCoins();
	let showButtons = modalData?.buttons && modalData?.buttons?.length > 0;

	const executeCode = (resultString,url) => {
 		try {
			const result = eval(`${resultString}('${url}')`);
			closeModal();
		} catch (error) {
			closeModal();
		}
	};

	const handleClick = (buttonDetail) => {
		// Retrieve the counter from localStorage or initialize it to 0
		let toastDangerCount = parseInt(localStorage.getItem('fail_load_count')) || 0;
	
		if (buttonDetail?.action && buttonDetail?.action === 'navigate') {			
			dispatch(readMessageAction({messageId: modalData._id}));
			window.open(buttonDetail.data, '_blank');
			closeModal();
			return;
		}

		if (buttonDetail?.action && buttonDetail?.action === 'toast_danger') {
			toastDangerCount++; // Increment the counter
			localStorage.setItem('fail_load_count', toastDangerCount); // Save updated counter
	
			if (toastDangerCount >= 3) {
				localStorage.setItem('fail_load_count', 0);
				if (window.Telegram && window.Telegram.WebApp) {
					Telegram.WebApp.close();
				} else {
					console.error('Telegram WebApp API is not available.');
				}
			} else {
				// Reload the page for the first and second occurrences
				navigate(`/`);
				window.location.reload();
			}
	
			closeModal();
			return;
		}
				
		if (buttonDetail?.action && buttonDetail?.action === 'close') {
			closeModal();
			dispatch(getPopupMessageAction());
		} else {
			let actionString = buttonDetail?.action;
			let actionTrimString = actionString.replace(/[()]/g, '');
			executeCode(actionTrimString, buttonDetail?.action_url);
		}
	};	

	useEffect(() => {
		const claimRewardUpdateCallback = (referral)=>{
			const friendsCount = parseInt(referral.friends);
			const friendRewardCoins = parseInt(referral.coins);
			if (friendsCount > 0){
				const rewardCoins = friendsCount * friendRewardCoins;
				const newUserCoins = currentCoinsRef.current + rewardCoins;
				dispatch(AddToLocalCoinsAction(rewardCoins));
				dispatch(AddCoinAction({ amount: rewardCoins, coinType: 'click' }));
				localStorage.setItem(UserPointsKey, newUserCoins);
				const newUserObject = {
					...user,
					balance: newUserCoins
				};
				dispatch(UpdateBalanceAfterClaimAction(newUserObject));
				fireFriendConfetti();
			}
		}

		const claimArcadeCoinsCallback = (arcade_coins)=>{
			const rewardCoins = parseInt(arcade_coins);
			const newUserCoins = currentCoinsRef.current + rewardCoins;
			dispatch(AddToLocalCoinsAction(rewardCoins));
			dispatch(AddCoinAction({ amount: rewardCoins, coinType: 'arcade' }));
			localStorage.setItem(UserPointsKey, newUserCoins);
			const newUserObject = {
				...user,
				balance: newUserCoins
			};
			dispatch(UpdateBalanceAfterClaimAction(newUserObject));
			fireArcadeConfetti();
		}
				
		console.log(modalData);

		if (modalData._id) {
			if (modalData.action ==='navigate') {
				//console.log("don't mark read");
			} else {
				dispatch(readMessageAction({messageId: modalData._id}));
			}			
		}
		
		if (modalData.action ==='claim_referral') {
			if (user?.type === "Returning") {
				dispatch(FriendsReferBonusSndClickAction())
			} else {
				dispatch(FriendsReferAcceptAction());
				dispatch(FriendsReferBonusAccClickAction());
			}
			dispatch(claimRewardActions({ user_id: user?.id, ref_type: 'friend' }, claimRewardUpdateCallback));
		} else if (modalData.action ==='arcade_coins') {
			//alert('arcade_coins',modalData.data.coins);
            //dispatch(claimRewardActions({ user_id: user?.id, ref_type: 'arcade' }), claimArcadeCoinsCallback);
			claimArcadeCoinsCallback(modalData.data.Coins);
        }
	}, []);

	return (
		<div className="flex items-center flex-col justify-center fixed h-full z-[999] left-0 top-0 w-full ">
			{isModalOpen && (
				<div className="flex items-center justify-center flex-col">
					<div className="max-w-[300px] bg-[#030303] p-7 rounded-3xl shadow-[0px_0px_8px_rgba(164,142,56,1)]">
						<img src={modalData?.image} className="max-w-[100px] mx-auto block"/>
						<h3 className="text-center text-2xl leading-7 my-2 text-[#fff] font-bold"> {modalData?.title} </h3>
						<p className="text-center font-normal text-[14px] text-[#fff]"> {modalData?.text} </p>
						<div className="flex items-center gap-2 justify-center mt-2 text-[#fff]">
							{modalData?.data?.Coins && (
								<>
									<span className="font-bold">{modalData.data.Coins.toLocaleString()}</span>
									<img src={DollarImage} alt="" className="max-w-[25px]" />
								</>
							)}
						</div>
						{showButtons && (
							<div className="text-center">
								{modalData?.buttons.map((btn, index) => {
									return btn?.icon ? (
										<button key={`btn_key_${index}`} onClick={()=>handleClick(btn)} className="flex items-center justify-center gap-1 w-60 btnBg text-[#fff] mt-2" >
											<span className="font-semibold">{btn.text}</span>
											{
											btn?.text.toLowerCase() ==='invite' ?
												<img src={addFriendIcon} alt={btn.text} className="max-w-[20px]"  />
											:
												<IconComponent imgSrc={btn.icon} alt={`${btn.text}_button_img`}   />
											}

										</button>
									) : (
										<button key={`btn_key_${index}`}  onClick={()=>handleClick(btn)} className="w-60 btnBg text-[#fff] mt-2 font-semibold" >
											<span>{btn.text}</span>
										</button>
									);
								})}
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default DynamicPopUpComponent;
