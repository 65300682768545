import React from "react";

const ClaimPopup = ({ type, title, message, ok_button, cancel_button, onOk, onCancel }) => {
  return (
    <div className="flex items-center flex-col justify-center fixed h-full z-[999] left-0 top-0 w-full">
      <div className="max-w-[300px] bg-[#030303] p-7 rounded-3xl shadow-[0px_0px_8px_rgba(164,142,56,1)]">
        {/* Title */}
        <h3 className="text-center text-2xl leading-7 my-2 text-[#fff] font-bold">
          {title}
        </h3>

        {/* Message */}
        <p
          className="text-center font-normal text-[14px] text-[#fff]"
          dangerouslySetInnerHTML={{
            __html: message.replace(/\n/g, "<br/>"),
          }}
        ></p>

        {/* OK Button */}
        <div className="text-center mt-4">
          <button
            onClick={onOk}
            className="flex items-center justify-center btnBgEx text-[#fff] font-semibold"
          >
            <span>{ok_button}</span>
            
          </button>
        </div>

        {/* Cancel Button (if provided) */}
        {cancel_button && (
          <div className="text-center mt-2 mb-[-8px]">
            <button
              onClick={onCancel}
              className="text-[#FFC107] font-medium hover:underline"
            >
              {cancel_button}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClaimPopup;
